import { Component, Prop } from 'vue-property-decorator';
import Globals from '@/globals';
import PlayerApi from '@/support/playerApi';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { ServerDataModule } from '@/store/serverData';
import { EventBus } from '@/support/eventBus';

@Component
export default class AddEveryoneAtMicrosoftComponent extends StudioPageBase {
    options = {
    };

    get serverData() {
        return ServerDataModule.serverData;
    }

    key = 'addEveryoneAtMicrosoft';
    saveDisabled: boolean = true;

    busy = false;

    @Prop({ default: <any>'auto' })
    height: string;

    created() {
        Debug.setDebugModule('AddEveryoneAtMicrosoft', this);

        super.created('addEveryoneAtMicrosoft', true);
    }

    mounted() {
        super.mounted();
    }

    show() {
        this.saveDisabled = true;
        (<any>this.$modal).show(this.key);
    }

    hide() {
        this.busy = false;
        this.saveDisabled = true;
        (<any>this.$modal).hide(this.key);
    }

    async done() {
        try {
            this.busy = true;
            return await this.addSpecialGroup(Globals.EveryoneAtMicrosoft);
        } finally {
            this.hide();
        }
    }

    cancel() {
        this.hide();
    }

    async addSpecialGroup(title: string) {
        try {
            let response = await PlayerApi.addPermissionWorker(this.studio.studio,
                {
                    name: title,
                });

            EventBus.Instance.$emit(EventBus.PermissionsChanged, response);

            Util.showToast('Added ' + title);
            return response;
        } catch (err) {
            let message = err.message || 'ERROR';
            Util.showError('addSpecialGroup failed to add permission error: ' + message);
            return null;
        }
    }

}
